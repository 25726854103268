<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable :value="products" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<div>
								<InputSwitch v-model="$store.state.opc"  style="margin-top: 0" class="mr-2"/>
								<InputText id="fecha"  v-model="$store.state.ini" type="date" v-if="!$store.state.opc" class="mr-2"/>
								<InputText id="fecha"  v-model="$store.state.fin" type="date" v-if="!$store.state.opc" class="mr-2"/>
								<Dropdown id="state" v-model="$store.state.year" :options="$store.state.years" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." @change="$store.state.mes=nul" v-if="$store.state.opc"></Dropdown>
								<Dropdown id="state" v-model="$store.state.mes" :options="$store.state.meses" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." v-if="$store.state.opc"></Dropdown>
								<Button icon="pi pi-search" class="p-button-rounded p-button-info mr-2" @click="Buscar"/>
							</div>
							<Button label="Calendario" icon="pi pi-calendar" class="p-button-primary mr-2" @click="calendario=true" v-if="products.length"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
								<InputText v-model="filtro" placeholder="Buscar..." @keyup="Filtrar"/> Total: {{products.length}}
                            </span>
						</div>
					</template>
					<Column field="fechad" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.dia}}<br>
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="hora" header="Hora" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.hora}}<br>
							{{slotProps.data.espacio}}
						</template>
					</Column>
					<Column field="especialista" header="Especialidad" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.especialidad}}<br>
							{{slotProps.data.especialista}}
						</template>
					</Column>
					<Column field="paciente" header="Paciente" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.paciente}}
						</template>
					</Column>
					<Column header="Estatus" headerStyle="width: 50px">
						<template #body="slotProps">
							<span class="p-tag p-tag-danger" v-if="(!slotProps.data.procesado && !slotProps.data.pagado)">Pendiente</span>
							<span class="p-tag p-tag-warning"  v-if="slotProps.data.procesado && !slotProps.data.pagado">Por Pago</span>
							<span class="p-tag p-tag-success"  v-if="slotProps.data.procesado && slotProps.data.pagado">Pagado</span>
							<span class="p-tag p-tag-info"  v-if="!slotProps.data.procesado && slotProps.data.pagado">Prepagado</span>
						</template>
					</Column>
					<Column header="Acciones" headerStyle="width: 50px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="Editar(slotProps.data)" v-tooltip.bottom="'Editar'" v-if="edit && !slotProps.data.pagado"/>
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-info mr-2" @click="Editar(slotProps.data)" v-tooltip.bottom="'Editar'" v-if="(edit && slotProps.data.pagado && !slotProps.data.procesado)"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Editar ID'+product.id" :modal="true" class="p-fluid" :maximizable="true">
			<div class="fluid">    
				<div class="grid fluid mt-3">
                    <div class="col-8">
                        Fecha: <strong>{{product.fechad}}</strong> 
                    </div>        
                    <div class="col-4">
                        Hora: <strong>{{product.hora}}</strong> 
                    </div>        
                    <div class="col-8">
                        Paciente: <strong>{{product.paciente}}</strong> 
                    </div>        
                    <div class="col-4">
                        N° Cédula: <strong>{{product.cedula}}</strong> 
                    </div>        
                    <div class="col-8">
                        Especialidad: <strong>{{product.especialidad}}</strong> 
                    </div>        
                    <div class="col-4">
                        Ubicación: <strong>{{product.espacio}}</strong> 
                    </div>        
                    <div class="col-8">
                        Especialista: <strong>{{product.especialista}}</strong> 
                    </div>        
                    <div class="col-4">
                        Día: <strong>{{product.dia}}</strong> 
                    </div>        
                    <div class="col-12">
                        <DataTable :value="aranceles" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                            :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                            <Column field="selec" header="Selec" headerStyle="width: 50px">
                                <template #body="slotProps">
                                    <Checkbox id="binary" v-model="slotProps.data.selec" :binary="true" @change="Calcular" :disabled="product.pagado==true"/>
                                </template>
                            </Column>
                            <Column field="nombre" header="Nombre">
                                <template #body="slotProps">
                                    {{slotProps.data.nombre}}
                                </template>
                            </Column>
                            <Column field="monto" header="Monto" headerStyle="width: 100px">
                                <template #body="slotProps">
                                    {{slotProps.data.code}} {{Moneda(slotProps.data.monto)}}
                                </template>
                            </Column>
                            <Column field="cantidad" header="Cantidad" headerStyle="width: 100px">
                                <template #body="slotProps">
									<InputNumber id="cedula" v-model="slotProps.data.cantidad" integeronly required="true" min="1" :disabled="slotProps.data.selec==false" change="Calcular"/>
                                </template>
                            </Column>
                            <Column field="monto" header="Total" headerStyle="width: 100px">
                                <template #body="slotProps">
                                    {{slotProps.data.code}} {{Moneda(slotProps.data.monto*slotProps.data.cantidad)}}
                                </template>
                            </Column>
                        </DataTable>
                    </div>        
					<div class="col-4">
						<h5><strong>Monto a Pagar:</strong></h5>
					</div>
					<div class="col-4">
						<h5><strong>$ {{Moneda(total)}}</strong></h5>
						<h5 v-if="totalbs>0"><strong> Adicional Bs {{Moneda(totalbs)}}</strong></h5>
					</div>
                </div>
			</div>
			<template #footer>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="deleteDialog"  style="width: 30vw" :header="'Eliminar ID'+product.id" :modal="true">
            <div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres eliminar?</span>
			</div>
			<template #footer>
				<Button label="Si" icon="pi pi-check" class="p-button-secondary" @click="Eliminar" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteDialog = false"/>
			</template>
		</Dialog>

		<Dialog header="Calendario" v-model:visible="calendario" style="{width: '40vw'}" :maximizable="true" :modal="true">
			<Calendario :events="products"/>
        </Dialog> 

	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Citas','Procesar');
	import Funciones from "../service/Class";
	const Aplic = new Funciones();
	import Calendario from '../components/Calendario.vue';

	export default {
		components: {
			Calendario
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				resultado: [],
				productDialog: false,
				deleteDialog: false,
				selectedCustomers: null,
				filtro: null,
				filters: {},
				size: '50vw',
                aranceles: [],
				dataranceles: [],
				total: 0,
				totalbs: 0,
				opcion: {nombre: 'Pendientes', code: 0},
				opciones: [
                    {nombre: 'Pendientes', code: 0},
                    {nombre: 'Anteriores', code: 1},
                    {nombre: 'Pago Pendiente', code: 2},
                    {nombre: 'Prepagadas', code: 3},
					{nombre: 'Pagadas', code: 4},
                ],
				calendario: false,

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
                this.aranceles = [];
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					//this.products = response.result.citas;
                    this.dataranceles = response.result.aranceles;
					this.$store.commit('Loading');
				}); 
			},
			Buscar(){
                if(this.opcion){
					let opciones = {
						opc: this.$store.state.opc,
						ini: this.$store.state.ini,
						fin: this.$store.state.fin,
						mes: this.$store.state.mes.id,
						year: this.$store.state.year.id,
					};
                    Consulta.Procesar('Buscar',{
                        product: opciones
                    }).then(response => {
                        //this.$store.state.error = response;
                        this.products = response.result;
						this.resultado = response.result;
						this.Filtrar();
                        this.$store.commit('Loading');
                    });
                } 
            },
			Filtrar() {
				if (this.filtro==null || this.filtro==''){
					this.products = this.resultado;
				} else {
					this.products = this.resultado.filter(val => 
						val.dia.toUpperCase().includes(this.filtro.toUpperCase()) ||
						val.fechad.toUpperCase().includes(this.filtro.toUpperCase()) ||
						val.espacio.toUpperCase().includes(this.filtro.toUpperCase()) ||
						val.hora.toUpperCase().includes(this.filtro.toUpperCase()) ||
						val.especialidad.toUpperCase().includes(this.filtro.toUpperCase()) ||
						val.especialista.toUpperCase().includes(this.filtro.toUpperCase()) ||
						val.paciente.toUpperCase().includes(this.filtro.toUpperCase())
					);	
				}
			},
			Calcular(){
				this.total = 0;
				this.totalbs = 0;
				this.aranceles.forEach(element => {
					if(element.selec && element.moneda_id==1){
						this.totalbs = parseFloat(this.totalbs) + parseFloat(element.monto)*parseFloat(element.cantidad);
					}
					if(element.selec && element.moneda_id==2){
						this.total = parseFloat(this.total) + parseFloat(element.monto)*parseFloat(element.cantidad);
					}
				}); 
			},
			Editar(product) {
				this.aranceles = [];
				this.product = {...product};
                this.aranceles =  this.dataranceles.filter(val => val.especialidad_id == this.product.especialidad_id);
				this.aranceles.forEach(element => {
					this.product.deudas.forEach(element2 => {
						if(element.id == element2.arancel){
							element.selec = true;
							element.cantidad = element2.cantidad;
						}
					}); 
				}); 
				this.Calcular();
                this.productDialog = true;
			},
			Guardar() {
				if (this.product.hora == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Hora', life: 5000});
                } else if (this.product.especialidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Especialidad', life: 5000});
                } else if (this.product.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Fecha', life: 5000});
                } else if (this.product.especialista == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Especialista', life: 5000});
				} else {
					this.product.aranceles = this.aranceles.filter(val => val.selec);
					var caso = 'UPD';
					if (this.nuevo) {caso = 'INS';}
					Consulta.Procesar(caso,{
						product: this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.Buscar();
							this.productDialog = false;
							this.product = {};
						}
					});
				}
			},	
			Confirmar(product) {
				this.product = product;
                this.product.editar = false;
				this.deleteDialog = true;
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
                    //this.$store.state.error =response;
					this.$store.commit('Loading');
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
			Moneda(value) {
				return Aplic.Moneda(value);
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
